'use client';

import {
  ButtonIconHighlightSize,
  FsButtonIconHighlight,
  FsHeading,
  HeadingSize,
  HeadingTag,
} from '@printi/ds-offset-react-core';
import React, { useEffect, useRef, useState } from 'react';
import Link from 'next/link';
import { useLayout } from '../Media/Layout';
import { TProduct } from '../../repositories/aecomStrapiCms/product/types';
import { CardProduct } from '../CardProduct';
import { CardProductSkeleton } from '../CardProductSkeleton';
import * as S from './Carousel.styles';
import { useCarouselHooks } from './Carousel.hooks';

interface CarouselProps {
  heading?: string;
  cards?: TProduct[] | null;
  loading?: 'lazy' | 'eager';
  disableNavigation?: boolean;
  itemsPerView?: number;
}

export const Carousel = ({
  heading = 'Heading',
  cards,
  loading = 'lazy',
  disableNavigation = false,
  itemsPerView = 6,
}: CarouselProps) => {
  const { isMobile, setShowSearchPage } = useLayout();
  const [activeIndex, setActiveIndex] = useState<number>(0);
  const containerRef = useRef<HTMLDivElement>(null);

  const totalCards = cards?.length || 0;
  const isLoading = !cards || cards.length === 0;
  const shouldShowNavigation = !disableNavigation && !isLoading;

  const { handlePrev, handleNext } = useCarouselHooks({
    setActiveIndex,
    activeIndex,
    totalCards,
    itemsPerView,
    containerRef,
  });

  const isAtStart = activeIndex === 0;
  const isAtEnd = activeIndex >= totalCards - itemsPerView;

  useEffect(() => {
    const element = document.getElementById('CarouselProducts');
    const observer = new IntersectionObserver((element) => {
      if (element[0]?.isIntersecting) {
        const itemsMap = cards?.map((item) => ({
          item_id: item.attributes.final_product_id,
          item_name: item.attributes.name,
          affiliation: 'Printi',
          discount: 0,
          index: 0,
          item_brand: 'Printi',
          item_list_id: heading && heading.toLowerCase().replace(' ', '_'),
          item_list_name: heading,
          price: item?.attributes?.minimal_price?.toString() || '0',
          quantity: item.attributes.minimal_quantity,
        }));

        if (cards) {
          window.dataLayer.push({ ecommerce: null });
          window.dataLayer.push({
            event: 'view_item_list',
            event_name: 'view_item_list',
            item_list_id: heading && heading.toLowerCase().replace(' ', '_'),
            item_list_name: heading,
            ecommerce: {
              currency: 'BRL',
              items: itemsMap,
            },
          });
        }
      }
    });

    if (element) {
      observer.observe(element);
    }

    return () => {
      if (element) {
        observer.unobserve(element);
      }
    };
  }, [cards, heading]);

  const calculateWidth = () => {
    if (isMobile || isLoading) {
      return '100%';
    }

    const itemWidth = 100 / itemsPerView;
    const totalWidth = `${totalCards * itemWidth}%`;

    return `${totalWidth}%`;
  };

  return (
    <S.Carousel className="carousel-component">
      <S.HeadingControlsWrapper>
        <FsHeading
          tag={HeadingTag.H2}
          size={isMobile ? HeadingSize.XS : HeadingSize.SM}
        >
          {heading}
        </FsHeading>
        <S.ButtonsWrapper>
          {shouldShowNavigation ? (
            <>
              <FsButtonIconHighlight
                size={ButtonIconHighlightSize.SM}
                icon="NavigationLeftLine"
                onClick={handlePrev}
                disabled={isAtStart}
              />
              <FsButtonIconHighlight
                size={ButtonIconHighlightSize.SM}
                icon="NavigationRightLine"
                onClick={handleNext}
                disabled={isAtEnd}
              />
            </>
          ) : (
            <>
              <S.NavigationSkeleton />
              <S.NavigationSkeleton />
            </>
          )}
        </S.ButtonsWrapper>
      </S.HeadingControlsWrapper>
      <S.CardsCarouselContainer id="CarouselProducts" ref={containerRef}>
        <S.CardsCarouselWrapper
          style={
            !isMobile && !isLoading
              ? {
                  width: calculateWidth(),
                }
              : {}
          }
        >
          {!isLoading ? (
            cards.map((product) => (
              <S.CardProductWrapper key={product.id}>
                <Link
                  href={`/${product.attributes.slug}/`}
                  onClick={() => setShowSearchPage(false)}
                >
                  <CardProduct
                    product={product}
                    description="A partir de"
                    carrousel={true}
                    loading={loading}
                  />
                </Link>
              </S.CardProductWrapper>
            ))
          ) : (
            <>
              {Array.from({ length: 6 }).map((_, index) => (
                <S.CardProductWrapper key={`skeleton-${index}`}>
                  <CardProductSkeleton />
                </S.CardProductWrapper>
              ))}
            </>
          )}
        </S.CardsCarouselWrapper>
      </S.CardsCarouselContainer>
    </S.Carousel>
  );
};
