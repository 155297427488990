'use client';

import React, { useCallback, useEffect, useRef } from 'react';

interface CarouselHooksProps {
  setActiveIndex: React.Dispatch<React.SetStateAction<number>>;
  activeIndex: number;
  totalCards: number;
  itemsPerView: number;
  containerRef: React.RefObject<HTMLDivElement>;
}

export const useCarouselHooks = ({
  setActiveIndex,
  activeIndex,
  totalCards,
  itemsPerView,
  containerRef,
}: CarouselHooksProps) => {
  const isScrollingRef = useRef(false);
  const scrollTimeoutRef = useRef<NodeJS.Timeout>();

  const handlePrev = () => {
    if (isScrollingRef.current) {
      return;
    }
    setActiveIndex((prevIndex: number) => {
      if (prevIndex === 0) {
        return prevIndex;
      }
      return prevIndex - 1;
    });
  };

  const handleNext = () => {
    if (isScrollingRef.current) {
      return;
    }
    setActiveIndex((prevIndex: number) => {
      const maxIndex = Math.max(0, totalCards - itemsPerView);
      if (prevIndex >= maxIndex) {
        return prevIndex;
      }
      return prevIndex + 1;
    });
  };

  const handleScroll = useCallback(() => {
    if (!containerRef.current || isScrollingRef.current) {
      return;
    }

    if (scrollTimeoutRef.current) {
      clearTimeout(scrollTimeoutRef.current);
    }

    scrollTimeoutRef.current = setTimeout(() => {
      if (!containerRef.current) {
        return;
      }
      const { scrollLeft, scrollWidth } = containerRef.current;
      const itemWidth = scrollWidth / totalCards;
      const currentIndex = Math.round(scrollLeft / itemWidth);

      if (currentIndex !== activeIndex) {
        setActiveIndex(currentIndex);
      }
    }, 50);
  }, [containerRef, totalCards, activeIndex, setActiveIndex]);

  useEffect(() => {
    const container = containerRef.current;
    if (!container) {
      return;
    }

    container.addEventListener('scroll', handleScroll);
    return () => {
      container.removeEventListener('scroll', handleScroll);
      if (scrollTimeoutRef.current) {
        clearTimeout(scrollTimeoutRef.current);
      }
    };
  }, [containerRef, handleScroll]);

  useEffect(() => {
    const container = containerRef.current;
    if (!container) {
      return;
    }

    isScrollingRef.current = true;
    const itemWidth = container.scrollWidth / totalCards;

    container.scrollTo({
      left: activeIndex * itemWidth,
      behavior: 'smooth',
    });

    const timer = setTimeout(() => {
      isScrollingRef.current = false;
    }, 300);

    return () => clearTimeout(timer);
  }, [activeIndex, containerRef, totalCards]);

  return {
    handlePrev,
    handleNext,
  };
};
