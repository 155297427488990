import styled from 'styled-components';
import { media } from '@mf/common/utils/styles/index';

export const Carousel = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--spacing-3x);
  width: 100%;

  @media screen and (min-width: 1025px) {
    gap: var(--spacing-4x);
  }
`;

export const HeadingControlsWrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  gap: var(--spacing-1x);
`;

export const CardsCarouselContainer = styled.div`
  overflow-x: auto;
  overflow-y: hidden;
  padding-bottom: var(--spacing-half);
  width: 100%;
  -webkit-overflow-scrolling: touch;
  scroll-behavior: smooth;
  overscroll-behavior-x: contain;

  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  ${media.lessThan('mobile')} {
    margin: 0 -16px;
    padding: 0 16px;
    width: auto;
  }
`;

export const CardsCarouselWrapper = styled.div`
  display: flex;
  gap: var(--spacing-3x);
  transition: all 0.3s ease;
`;

export const CardProductWrapper = styled.div`
  display: flex;
  min-width: 207px;
  max-width: 207px;
  cursor: pointer;
  scroll-snap-align: start;
  flex: 0 0 auto;

  @media screen and (max-width: 1024) {
    min-width: 218px;
    max-width: 218px;
  }

  @media screen and (max-width: 768px) {
    min-width: 240px;
    max-width: 240px;
  }

  a {
    display: block;
    width: 100%;

    div {
      position: relative;
    }
  }

  ${media.greaterThan('touch')} {
    width: 322px;
  }
`;

export const NavigationSkeleton = styled.div`
  width: 48px;
  height: 48px;
  border-radius: 50%;
`;
