import { useCallback } from 'react';
import { TCmsMedia } from '@mf/common/repositories/aecomStrapiCms/defaults/types';

export const useCardProductHooks = () => {
  const getImage = useCallback((images: TCmsMedia | undefined) => {
    if (images?.attributes.url) {
      return images?.attributes.url;
    }

    return 'https://d1br4h274rc9sc.cloudfront.net/content/default_Product_Img_292cda62b1.png';
  }, []);

  return {
    getImage,
  };
};
