import {
  FsHeading,
  FsSkeleton,
  HeadingSize,
  HeadingTag,
} from '@printi/ds-offset-react-core';
import * as S from './CardProductSkeleton.styles';

export const CardProductSkeleton = () => {
  return (
    <S.Container>
      <S.Img>
        <FsSkeleton />
      </S.Img>
      <FsHeading size={HeadingSize.XS} tag={HeadingTag.SPAN}>
        <FsSkeleton />
      </FsHeading>
      <S.Description>
        <FsSkeleton />
      </S.Description>
      <S.Price>
        <FsSkeleton />
      </S.Price>
    </S.Container>
  );
};
