/* eslint-disable @typescript-eslint/no-explicit-any */
import { getTraceParent } from '../utils/tracing';
import { LogProvider } from './logProvider';

export const googleAnalyticsProvider: LogProvider = {
  trackEvent: (eventType, details = {}) => {
    const traceparent = getTraceParent();
    const dataLayer = window?.dataLayer;

    if (!dataLayer) {
      return;
    }

    dataLayer.push({
      event: eventType,
      ...details,
      traceparent,
    });
  },

  logError: (error: any, details = {}) => {
    const traceparent = getTraceParent();
    const dataLayer = window?.dataLayer;

    if (!dataLayer) {
      return;
    }

    dataLayer.push({
      event: 'exception',
      description: error?.message || 'Unknown error',
      fatal: true,
      ...details,
      traceparent,
    });
  },
};
