'use client';

import {
  CurrencyPosition,
  CurrencySize,
  FsCardProduct,
  FsCurrency,
  FsDescription,
  FsHeading,
  HeadingSize,
  HeadingTag,
} from '@printi/ds-offset-react-core';
import { formatCurrency } from '@brazilian-utils/brazilian-utils';
import Image from 'next/image';
import { TProduct } from '../../repositories/aecomStrapiCms/product/types';
import { useCardProductHooks } from './CardProduct.hooks';
// import { useCardProductHooks } from './CardProduct.hooks';

interface CardProductProps {
  product?: TProduct;
  description?: string;
  carrousel?: boolean;
  loading?: 'lazy' | 'eager';
}

export const CardProduct = ({
  product,
  description = 'A partir de',
  carrousel,
  loading = 'lazy',
}: CardProductProps) => {
  const { getImage } = useCardProductHooks();

  const defaultImg =
    'https://d1br4h274rc9sc.cloudfront.net/content/Screenshot_2024_05_22_at_16_02_34_e5a79bd368.png';

  return (
    <FsCardProduct
      image={
        <Image
          src={getImage(product?.attributes?.small_image?.data) || defaultImg}
          alt={product?.attributes?.short_description || ''}
          sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 33vw"
          fill
          loading={loading}
        />
      }
      hoverImage={
        (
          <Image
            src={
              product?.attributes?.small_image_hover?.data?.attributes?.url ||
              defaultImg
            }
            alt={product?.attributes?.short_description || ''}
            fill
            loading={loading}
          />
        ) as React.ReactNode & string
      }
      showTag={false}
    >
      <>
        <FsHeading
          tag={carrousel ? HeadingTag.H3 : HeadingTag.H2}
          size={HeadingSize.XS}
          style={{ textWrap: 'wrap' }}
        >
          {product?.attributes.name || 'Produto'}
        </FsHeading>
        <FsDescription>{description}</FsDescription>
        <FsCurrency
          price={`R$ ${formatCurrency(product?.attributes?.minimal_price || 0, { precision: 2 }) || '100,00'} ${'/'}`}
          size={CurrencySize.SM}
          description={`${product?.attributes?.minimal_quantity || '1'} unid.`}
          descriptionPosition={CurrencyPosition.Right}
        />
      </>
    </FsCardProduct>
  );
};
