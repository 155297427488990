/* eslint-disable @typescript-eslint/no-explicit-any */
import { useCallback, useMemo, useState } from 'react';
import logConfig from '../../config/logs/logConfig.json';
import {
  EventConfig,
  // LogErrorType,
  LogEventType,
  LogProvider,
  ProviderConfig,
  ProviderKey,
} from './providers/logProvider';
import { newRelicProvider } from './providers/newRelicProvider';
import { getTraceParent } from './utils/tracing';
import { googleAnalyticsProvider } from './providers/googleAnalyticsProvider';

let globalLogError: any | null = null;

const parsedEventConfig = logConfig.events as Record<
  LogEventType,
  ProviderKey[]
>;

const parsedProviderConfig = Object.entries(parsedEventConfig).reduce(
  (acc, [event, providers]) => {
    providers.forEach((provider) => {
      if (!acc[provider]) {
        acc[provider] = [];
      }
      acc[provider].push(event as LogEventType);
    });
    return acc;
  },
  {} as Record<ProviderKey, LogEventType[]>,
);

export function useLogManager() {
  const [eventConfig] = useState<EventConfig>(parsedEventConfig);
  const [providerConfig] = useState<ProviderConfig>(parsedProviderConfig);

  const providers = useMemo<Record<ProviderKey, LogProvider>>(
    () => ({
      newrelic: newRelicProvider,
      ga: googleAnalyticsProvider,
    }),
    [],
  );

  const trackEvent = useCallback(
    (eventType: LogEventType, details?: Record<string, unknown>) => {
      const providersForEvent = eventConfig[eventType] || [];
      providersForEvent.forEach((providerKey) => {
        const provider = providers[providerKey];

        if (provider && providerConfig[providerKey]?.includes(eventType)) {
          provider.trackEvent(eventType, details);
        }
      });
    },
    [eventConfig, providerConfig, providers],
  );

  const logError = useCallback<any>(
    (eventType: any, details: any) => {
      const providersForEvent = eventConfig['error'] || [];

      providersForEvent.forEach((providerKey) => {
        const provider = providers[providerKey];

        if (provider && providerConfig[providerKey]?.includes('error')) {
          provider.logError(eventType, details);
        }
      });
    },
    [eventConfig, providerConfig, providers],
  );

  globalLogError = logError;

  return {
    trackEvent,
    logError,
    getTraceParent,
  };
}

export const getGlobalLogError = (): any => {
  if (!globalLogError) {
    throw new Error(
      'logError não foi inicializado. Certifique-se de que o useLogManager foi chamado.',
    );
  }
  return globalLogError;
};
