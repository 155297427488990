import styled from 'styled-components';
import { media } from '@mf/common/utils/styles/index';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--spacing-1_5x);

  max-width: 322px;
  width: 100%;

  span {
    max-width: 177px;
    height: 25px;

    ${media.lessThan('touch')} {
      max-width: var(--size-15x);
    }

    ${media.lessThan('mobile')} {
      min-width: unset;
      max-width: 152px;
      max-height: 320px;
    }

    > div {
      height: 100%;
      width: 100%;
    }
  }
`;

export const Img = styled.div`
  height: 290px;
  width: 100%;

  ${media.lessThan('touch')} {
    height: 320px;
  }

  ${media.lessThan('mobile')} {
    max-width: 152px;
    max-height: 202px;
  }

  > div {
    height: 100%;
    width: 100%;
  }
`;

export const Description = styled.div`
  max-width: 76px;
  height: 18px;

  > div {
    height: 100%;
    width: 100%;
  }
`;

export const Price = styled.div`
  max-width: 135px;
  height: 20px;

  > div {
    height: 100%;
    width: 100%;
  }
`;
